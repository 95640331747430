let plannerLocalStorageName = "tripPlanner"


const addToTripPlanner = document.querySelectorAll('.js--addToTripPlanner');
const addMemberToTripPlanner = document.querySelectorAll('.js--addMemberToTripPlanner');

function insertBefore(el, referenceNode) {
  referenceNode.parentNode.insertBefore(el, referenceNode);
}

let getPlannedTrips = () => {
  plannedTrips = [];
  if (localStorage.getItem(plannerLocalStorageName)) {
    plannedTrips = JSON.parse(localStorage.getItem(plannerLocalStorageName));
  }
}

let closeAlerts = () => {
  setTimeout(function () {
    $('.alert').alert('close');
  }, 4000);
}


// Icon Only
Array.from(addMemberToTripPlanner).forEach(planner => {
  window.addEventListener("load", function () {
    getPlannedTrips();

    if (plannedTrips.some(function (el) { return el.url == planner.dataset.plannerUrl; })) {
      isAdded();
    }

  }, false);

  let isAdded = () => {
    planner.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>
    `;
  }

  planner.addEventListener('click', function () {
    getPlannedTrips();


    let data = { title: this.dataset.plannerTitle, url: this.dataset.plannerUrl };

    // Only add if not already planned.
    if (!plannedTrips.some(function (el) { return el.url == data.url; })) {
      // ADD
      plannedTrips.push(data);
      isAdded();
      localStorage.setItem(plannerLocalStorageName, JSON.stringify(plannedTrips));
    } else {
      // Remove if Button Clicked Again
      var filteredAry = plannedTrips.filter(function (e) { return e.url !== data.url })
      localStorage.setItem(plannerLocalStorageName, JSON.stringify(filteredAry));
      planner.classList.replace("btn-tertiary", "btn-muted");
      planner.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>`;
    }

    let updateCount = () => {
      getPlannedTrips();
      localStorage.setItem('plannerCount', plannedTrips.length);
      if (plannedTrips.length > 0) {
        document.getElementsByClassName('js--plannerCount')[0].style.display = 'flex';
      } else {
        document.getElementsByClassName('js--plannerCount')[0].style.display = 'none';
      }
      document.getElementsByClassName('js--plannerCount')[0].innerHTML = plannedTrips.length

    }
    updateCount();

  });
});

// The Button
Array.from(addToTripPlanner).forEach(planner => {
  window.addEventListener("load", function () {
    getPlannedTrips();

    if (plannedTrips.some(function (el) { return el.url == planner.dataset.plannerUrl; })) {
      isAdded();
    }

  }, false);

  let isAdded = () => {
    planner.classList.replace("btn-primary", "btn-secondary");
    planner.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg> <span class="pl-2">Added to Trip Planner</span>`;
  }

  planner.addEventListener('click', function () {
    getPlannedTrips();


    let data = { title: this.dataset.plannerTitle, url: this.dataset.plannerUrl };

    // Only add if not already planned.
    if (!plannedTrips.some(function (el) { return el.url == data.url; })) {
      // ADD
      plannedTrips.push(data);
      isAdded();
      localStorage.setItem(plannerLocalStorageName, JSON.stringify(plannedTrips));
      let alert = document.createElement('div');
      alert.className = "alert alert-success alert-dismissible fade show";
      alert.innerHTML = `${data.title} has been added to your <a href="/trip-planner">Trip Planner</a>.\
      <button aria-label="Close" class="close" data-dismiss="alert" type="button" >\
      <span aria-hidden="true">&times;</span>\
      </button>\
    `;


      insertBefore(alert, planner);
      closeAlerts();

    } else {
      // Remove if Button Clicked Again
      var filteredAry = plannedTrips.filter(function (e) { return e.url !== data.url })
      localStorage.setItem(plannerLocalStorageName, JSON.stringify(filteredAry));
      if (planner.classList.contains("btn-outline")) {
        planner.classList.replace("btn-secondary", "btn-primary");
      }
      planner.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg> <span class="pl-2">Add to your Trip Planner</span>`;


      let alert = document.createElement('div');
      alert.className = "alert alert-warning alert-dismissible fade show";
      alert.innerHTML = `${data.title} has been removed from your <a href="/trip-planner">Trip Planner</a>.\
      <button aria-label="Close" class="close" data-dismiss="alert" type="button" >\
      <span aria-hidden="true">&times;</span>\
      </button>`;


      insertBefore(alert, planner);
      closeAlerts();
    }

    let updateCount = () => {
      getPlannedTrips();
      localStorage.setItem('plannerCount', plannedTrips.length);
      if (plannedTrips.length > 0) {
        document.getElementsByClassName('js--plannerCount')[0].style.display = 'flex';
      } else {
        document.getElementsByClassName('js--plannerCount')[0].style.display = 'none';
      }
      document.getElementsByClassName('js--plannerCount')[0].innerHTML = plannedTrips.length

    }
    updateCount();

  });
});
